<template>
  <div class="main">
    <van-loading
      class="loading"
      type="spinner"
      color="#1989fa"
      size="24"
      v-if="!session || !initBounds"
    />
    <div v-if="!star && !mcn">
      <unbound v-if="initBounds && session && stars.length === 0 && mcns.length === 0" />
      <bounds
        :mcns="mcns"
        :stars="stars"
        :session="session"
        v-else-if="initBounds && session && stars.length + mcns.length >= 1"
        @unbindSuccess="handleUnbindSuccess"
        @toStarDetail="handleToStarDetail"
        @toMcnDetail="handleToMcnDetail"
      />
    </div>

    <star
      :data="star"
      :session="session"
      @changeBounds="handleChangeBounds"
      v-if="star"
    />
    <mcn
      :data="mcn"
      :session="session"
      @changeBounds="handleChangeBounds"
      v-if="mcn"
    />
  </div>
</template>

<script>
import Bounds from './Bounds';
import Mcn from './Mcn';
import Star from './Star';
import Unbound from './Unbound';

import { mapActions, mapState, useStore } from 'vuex';

export default {
  name: 'Establish',
  components: {
    Bounds,
    Mcn,
    Star,
    Unbound,
  },
  setup() {
    const store = useStore();

    return {
      setStar: (star) => store.commit('star/SetCurrentStar', star),
      setMcn: (mcn) => store.commit('star/SetCurrentMcn', mcn),
    };
  },
  data() {
    return {
      loading: true,
      initBounds: false,
    };
  },
  computed: {
    ...mapState({
      // loading:  (state) => state.session.loading,
      configured: (state) => state.wecom.configured,
      externalUserId: (state) => state.wecom.externalUserId,
      externalGroupChatId: (state) => state.wecom.externalGroupChatId,
      session: (state) => state.session.session,
      stars: (state) => state.session.bound_stars,
      mcns: (state) => state.session.bound_mcns,
      star: (state) => state.star.currentStar,
      mcn: (state) => state.star.currentMcn,
    })
  },
  watch: {
    star(newValue, oldValue) {
      console.log('~~~~~~star update~~~~~~~');
    },
    configured(newValue, oldValue) {
      console.log('configured:' + newValue);
      if (newValue) {
        this.getExternalCustomerUserIdOrGroupChatId();
      }
    },
    externalUserId(newValue, oldValue) {
      console.log('externalUserId:' + newValue);
      if (newValue) {
        this.toGetWeComSession('external_user', newValue);
      }
    },
    externalGroupChatId(newValue, oldValue) {
      console.log('externalGroupChatId:' + newValue);
      if (newValue) {
        this.toGetWeComSession('external_group_chat', undefined, newValue);
      }
    },
    stars(newValue, oldValue) {
      this.$nextTick(() => {
        if (this.mcn) {
          return;
        }
        if (this.star) {
          let exists = false;
          for (let i = 0; i < newValue.length; i++) {
            if (newValue[i].id === this.star.id) {
              exists = true;
              break;
            }
          }
          if (!exists) {
            this.setStar(null);
          }
        } else {
          if (newValue && newValue.length === 1 && (!this.mcns || this.mcns.length === 0)) {
            this.setStar(newValue[0]);
          }
        }
      });
    },
    mcns(newValue, oldValue) {
      this.$nextTick(() => {
        if (this.star) {
          return;
        }
        if (this.mcn) {
          let exists = false;
          for (let i = 0; i < newValue.length; i++) {
            if (newValue[i].id === this.star.id) {
              exists = true;
              break;
            }
          }
          if (!exists) {
            this.setMcn(null);
          }
        } else {
          if (newValue && newValue.length === 1 && (!this.stars || this.stars.length === 0)) {
            this.setMcn(newValue[0]);
          }
        }
      });
    },
  },
  created() {
    console.log('~~~~~created');
    if (this.session) {
      this.toGetWeComSessionBounds(this.session.id);
    }
  },
  methods: {
    async toGetWeComSession(type, externalUserId = undefined, externalGroupChatId = undefined) {
      const res = await this.getWeComSession({
        type,
        externalUserId,
        externalGroupChatId,
      }).catch(() => {
      });
      if (!res) return;

      await this.toGetWeComSessionBounds(res.id);
    },
    async toGetWeComSessionBounds(sessionId) {
      const res = await this.getWeComSessionBounds({
        sessionId,
      }).catch(() => {
      });
      this.initBounds = true;
      // if (!res) return;
    },
    handleUnbindSuccess() {
      this.toGetWeComSessionBounds(this.session.id);
    },
    handleToStarDetail(data) {
      this.setStar(data);
    },
    handleToMcnDetail(data) {
      this.setMcn(data);
    },
    handleChangeBounds() {
      this.setStar(null);
      this.setMcn(null);
    },
    ...mapActions({
      getExternalCustomerUserIdOrGroupChatId:
        'wecom/getExternalCustomerUserIdOrGroupChatId',
      getWeComSession:
        'session/getWeComSession',
      getWeComSessionBounds:
        'session/getWeComSessionBounds',
    }),
  }
};
</script>

<style lang="less" scoped>
.establish-page {

}

.loading {
  position: absolute;
  left: calc(50% - 12px);
  top: 300px;
  z-index: 9999;
}
</style>