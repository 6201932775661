<template>
  <div class="unbound-page">
    <div>
      <img
        class="unbound-pic"
        src="@/assets/images/pic-noresult@2x.png"
      >
    </div>
    <div class="unbound-tips">
      <span>
        当前客户或群聊未绑定达人和机构
        <br>
        请先完成绑定
      </span>
    </div>
    <div
      class="bound-item bound-star"
      @click="goToBindingSessionStar"
    >
      <img
        class="icon"
        src="@/assets/images/icon-kol@2x.png"
      >
      <div class="bound-tips">
        <div class="title">
          <span>绑定达人</span>
        </div>
        <div class="brief">
          <span>搜索库内达人完成绑定，可查看资料，快捷录入达人权益</span>
        </div>
      </div>
      <div class="arrow" />
    </div>
    <div
      class="bound-item bound-mcn"
      @click="goToBindingSessionMcn"
    >
      <img
        class="icon"
        src="@/assets/images/pic-mcn@2x.png"
      >
      <div class="bound-tips">
        <div class="title">
          <span>绑定MCN机构</span>
        </div>
        <div class="brief">
          <span>搜索库内MCN并完成绑定，可查看机构权益，一般绑定群聊</span>
        </div>
      </div>
      <div class="arrow" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { Toast } from 'vant';

export default {
  name: 'Unbound',
  computed: {
    ...mapState({
      configured: (state) => state.wecom.configured,
      session: (state) => state.session.session,
    })
  },
  created() {

  },
  methods: {
    goToBindingSessionStar() {
      if(!this.session || !this.session.id){
        Toast.fail({
          message: '会话数据异常,请刷新后重试',
        });
        return
      }
      this.$router.push({
        name: 'BindingSessionStar',
        params: {
          id: this.session.id,
        },
      });
    },
    goToBindingSessionMcn() {
      if(!this.session || !this.session.id){
        Toast.fail({
          message: '会话数据异常,请刷新后重试',
        });
        return
      }
      this.$router.push({
        name: 'BindingSessionMcn',
        params: {
          id: this.session.id,
        },
      });
    },
    ...mapActions({
      getExternalCustomerUserIdOrGroupChatId:
        'wecom/getExternalCustomerUserIdOrGroupChatId',
      openExternalCustomerChat:
        'wecom/openExternalCustomerChat',
      openExternalGroupChat:
        'wecom/openExternalGroupChat',
    }),
  },
};
</script>

<style lang="less" scoped>
.unbound-page {
  padding-top: 100px;
  text-align: center;

  .unbound-pic {
    width: 185px;
    height: 165px;
  }

  .unbound-tips {
    font-size: 30px;
    font-weight: 400;
    color: #333;
    line-height: 44px;
    margin-top: 30px;
    margin-bottom: 70px;
  }

  .bound-star {
    background: #F3F6FF;

    .icon {
      width: 100px;
      height: 100px;
      flex-shrink: 0;
    }

  }

  .bound-mcn {
    background: #EFF8FF;

    .icon {
      width: 90px;
      margin-left: 5px;
      height: 100px;
      flex-shrink: 0;
    }

  }

  .bound-item {
    border-radius: 8px;
    border: 1px solid #0095FF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-left: 40px;
    margin-right: 40px;
    padding: 40px 40px 40px 60px;
    cursor: pointer;

    .bound-tips {
      text-align: left;
      margin-left: 50px;
      margin-right: 20px;

      .title {
        font-size: 30px;
        font-weight: 600;
        color: #333;
        line-height: 30px;
      }

      .brief {
        font-size: 26px;
        font-weight: 400;
        color: #666;
        line-height: 36px;
        margin-top: 20px;
      }
    }

    .arrow {
      width: 16px;
      height: 28px;
      background-image: url("~@/assets/images/icon-more-blue@2x.png");
      background-size: contain;
      background-repeat: no-repeat;
      flex-shrink: 0;
    }

    &:active {
      opacity: 0.8;
    }

    &:active .arrow {
      opacity: 0.8;
    }

  }

}
</style>